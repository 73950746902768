const zhCN = {
  // Layout.Menu
  '/orders': '电商订单',
  '/shipments': '邮寄信息',
  '/settings': '业务设置',
  '/profile': '个人中心',
  '/account/profile': '账号相关',
  '/stores': '电商对接',
  '/carriers': '物流账号',
  '/addresses': '预存地址',
  '/labels': '面单设置',
  '/packages': '包裹设置',
  // Order Page
  orders: '电商订单',
  order: '订单信息',
  shipped: '已寄出',
  customer: '收件人',
  editRecipient: '修改收件人信息',
  rates: '邮寄费用',
  download: '下载面单',
  viewOrder: '查看订单',
  buy: '购买面单',
  buy_again: '再次购买',
  downloadPackSlip: '下载箱单',
  hideOrder: '隐藏订单',
  uploadCsv: '批量上传',
  createLabel: '创建订单',
  create_another_label: '再次购买面单',
  createOrderManually: '创建订单',
  saveAndContinue: '保存并继续',
  sender: '寄件人',
  recipient: '收件人',
  from: '寄出地址',
  to: '收件地址',
  orderDetails: '订单详情',
  // Item
  items: '邮寄物品',
  item: '物品名称',
  deleteItem: '删除物品',
  no_item: '暂无物品',
  add_item: '添加物品',
  add_package: '添加包裹',
  items_summary: '物品总计',
  quantity: '数量',
  weight: '重量',
  value: '价格',
  fulfilled_order_items: '已邮寄的订单无法修改',
  item_count: '件',
  order_total: '订单总价',
  new_item: '添加物品',
  item_detail: '物品细节',
  item_description: '物品描述',
  unit_weight: '单位重量',
  unit_value: '单位价格',
  country_of_origin: '国家产地',
  require_greater_zero: '数值不能为0',
  item_total: '总计',
  // Package Info
  packageInfo: '包裹规格',
  custom_dimensions: '自定义包裹尺寸',
  dimentions: '包裹尺寸',
  dimentions_sub: '长 x 宽 x 高',
  package_weight: '包裹重量',
  use_item_weight: '使用物品重量',
  length: '长',
  width: '宽',
  height: '高',
  add_package_info: '添加包裹信息',
  // Shipment Options
  shipment_options: '邮寄选项',
  shipment_date: '邮寄日期',
  // Address Card
  return: '退货地址',
  address_list: '预存地址...',
  use_as_return: '与退货地址相同',
  // Rate Card
  package_info_missing: '请填写完整的包裹规格信息',
  jump_to_error: '查看问题',
  shipment_date_wrong: '邮寄日期不能是过去的日期',
  refresh: '刷新',
  rate_unavailable: '无法获得邮寄价格',
  fix_following: '请更正以下问题再试',
  cheapest: '价格最低',
  fastest: '时效最快',
  more_rates: '更多选择',
  // Shipment Page
  shipments: '邮寄信息',
  schedule_pickup: '预约取件',
  create_manifest: '生成 Manifest',
  carrier: '物流公司',
  service: '物流服务',
  tag: '标签',
  shipment_rate: '邮寄费用',
  tracking_status: '物流追踪',
  label_status: '面单状态',
  // Account Page
  accountTitle: '账号相关',
  profile: '基本资料',
  billing: '资费明细',
  curPassword: '当前密码',
  newPassword: '新密码',
  confirmPassword: '确认密码',
  allTransactions: '交易记录',
  dateTime: '日期时间',
  type: '类别',
  details: '订单信息',
  trackingNumber: '追踪单号',
  status: '状态',
  amount: '金额',
  Paid: '已付款',
  Unpaid: '未付款',
  Credited: '已结算',
  'Label payment': '购买面单',
  'Label refund': '面单退款',
  'Add Fund': '充值',
  Deduction: '扣费',
  exportCsv: '导出记录',
  // Carriers Page
  carriers: '物流账号',
  carriersSubtitle: 'ParcelsPro 折扣价格账号.',
  account: '账号',
  usDomestic: '美国本地',
  usInternational: '美国发国际',
  cnImport: '中国进口',
  testMode: '测试模式',
  testRate: '测试价格',
  updateAccount: '更新账号',
  // Address Page
  addresses: '预存地址',
  addressSubtitle: '保存常用的邮寄地址',
  locations: '地址列表',
  newAddress: '新建地址',
  editAddess: '修改地址',
  deleteAddress: '删除地址',
  defaultSender: '默认寄出地址',
  setDefaultSender: '设置为默认寄出地址',
  enableTestMode: '使用测试模式',
  testModeDescription:
    '测试模式可以让您打印样例面单来熟悉相关操作。测试模式下打印的面单不会产生费用。',
  // Label Page
  labels: '面单设置',
  labelsSubtitle: '设置常用的面单格式',
  labelsSettingTitle: '默认面单和箱单格式',
  labelFormat: '选择默认面单格式',
  packFormat: '选择默认箱单格式',
  // Package Page
  packages: '包裹设置',
  packagesSubtitle: '保存常用的包裹规格设置',
  weightUnitSelection: '选择默认重量单位',
  distanceUnitSelection: '选择默认尺寸单位',
  saveSettigns: '保存设置',
  // Login Page
  wrong_email_pass: '邮箱或密码错误',
  wrong_email_format: '邮箱格式不正确',
  enter_email: '请输入登录邮箱',
  enter_pass: '请输入密码',
  forgot_pass: '忘记密码',
  sign_up: '注册账号',
  sign_in: '登录',
  log_out: '登出',
  // Register Page
  confirm_pass: '确认密码',
  use_strong_pass: '请使用符合要求的密码',
  create_account: '注册账号',
  already_has_account: '已经拥有 ParcelsPro 账号',
  // Forgot Page
  send_email: '已成功发送邮件到所提供的邮箱',
  check_email_reset: '请根据邮件里的步骤重置您的账号密码',
  go_back_login: '返回登录页',
  trouble_login: '无法登陆',
  enter_email_reset: '请输入账号邮箱重置您的密码',
  go_back: '返回',
  reset_pass: '重置密码',
  reset_your_pass: '重置账号密码',
  enter_new_pass: '请输入新的账号密码',
  // Buy Modal
  no_fee_testing: '测试面单不会产生费用',
  insufficient_fund: '账号余额不足，请联系管理员充值',
  confirm_purchase: '确认购买',
  purchase_label: '购买面单',
  aknowledge_terms: '我同意上面条款',
  dhl_term:
    'DHL eCommerce 需要每天至少$200的单量. 如果当天单量无法满足$200, 将按照$200收取费用',
  label_prucahsed: '个面单已购买',
  shipping_labels: '物流面单',
  download_doc: '下载文件',
  packing_slip: '箱单文件',
  download_labels: '下载面单',
  // Common
  no_data: '暂无数据',
  edit: '修改',
  name: '姓名',
  company: '公司名称',
  email: '电子邮件',
  phone: '手机号码',
  country: '国家名称',
  street1: '地址信息1',
  street2: '地址信息2',
  city: '城市名称',
  state: '省份名称',
  zip: '邮政编码',
  cancel: '取消',
  apply: '确认',
  ok: '完成',
  searchSelect: '输入查找',
  requiredField: '需要填写',
  needPassword: '需要填写密码来更新基本资料',
  invalidFormat: '格式错误',
  enabled: '启用',
  disabled: '停用',
  save: '保存',
  weekPassword: '请按要求设置密码',
  passNotMatch: '您输入的密码不匹配',
  min8Chars: '8位字符以上',
  upperLowerNum: '大写字母、小写字母、数字',
  specialChars: '特殊符号： -#!$%^&*()_+|~=`{}[]:";\'<>?,./',
  date: '日期',
  copy: '复制',
  copied: '已复制',
  wrong_format: '格式错误',
  account_balance: '账户余额'
};

export default zhCN;
