import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import {
  SystemCarrier,
  SystemCarrierUpdateData
} from '../../custom_types/carrier-page';
import {
  AppThunk,
  CarriersState,
  RootState
} from '../../custom_types/redux-types';
import errorHandler from '../../shared/components/errorHandler';
import { SERVER_ROUTES } from '../../shared/utils/constants';

const initialState: CarriersState = {
  systemAccounts: [],
  loading: false,
  carrierModalLoading: false,
  showCarrierModal: false
};

export const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setSystemCarriers: (state, action: PayloadAction<SystemCarrier[]>) => {
      state.systemAccounts = action.payload;
    },
    updateSystemCarrier: (
      state,
      action: PayloadAction<SystemCarrierUpdateData>
    ) => {
      const { id, isTest, active } = action.payload;
      const account = state.systemAccounts.find((carrier) => carrier.id === id);
      if (account) {
        account.active = active;
        account.isTest = isTest;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCarrierModalLoading: (state, action: PayloadAction<boolean>) => {
      state.carrierModalLoading = action.payload;
    },
    setShowCarrierModal: (state, action: PayloadAction<boolean>) => {
      state.showCarrierModal = action.payload;
    }
  }
});

export const {
  setSystemCarriers,
  updateSystemCarrier,
  setLoading,
  setCarrierModalLoading,
  setShowCarrierModal
} = carriersSlice.actions;

export const fetchSystemCarriersHandler = (): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(SERVER_ROUTES.CARRIER_SETTINGS, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        const carriers = response.data;
        dispatch(setSystemCarriers(carriers));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
};

export const updateSystemCarrierHandler = (
  data: SystemCarrierUpdateData
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    if (data.isModalUpdate) {
      dispatch(setCarrierModalLoading(true));
    } else {
      dispatch(setLoading(true));
    }
    axios
      .put(SERVER_ROUTES.CARRIER_SETTINGS, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        const setting = response.data;
        dispatch(updateSystemCarrier(setting));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setCarrierModalLoading(false));
        dispatch(setLoading(false));
        dispatch(setShowCarrierModal(false));
      });
  }
};

export const selectSystemAccounts = (state: RootState): SystemCarrier[] =>
  state.carriers.systemAccounts;
export const selectCarriersLoading = (state: RootState): boolean =>
  state.carriers.loading;
export const selectCarrierModalLoading = (state: RootState): boolean =>
  state.carriers.carrierModalLoading;
export const selectShowCarrierModal = (state: RootState): boolean =>
  state.carriers.showCarrierModal;

export default carriersSlice.reducer;
