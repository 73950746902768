import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { AppThunk, OverseaWarehouseState, RootState } from "../../custom_types/redux-types";
import axios from '../../shared/utils/axios.base';
import errorHandler from "../../shared/components/errorHandler";
import { SERVER_ROUTES } from "../../shared/utils/constants";

const initialState: OverseaWarehouseState = {
    overseaWarehouse: undefined,
    loading: false
};

const overseawarehouseSlice = createSlice({
    name: 'overseawarehouse',
    initialState,
    reducers: {
        setOverseaWarehouse: (state, action) => {
            state.overseaWarehouse = action.payload;
        },
        setOverseaWarehouseLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { setOverseaWarehouse, setOverseaWarehouseLoading } = overseawarehouseSlice.actions;

export const getOverseaWarehouse = (): AppThunk => async (dispatch: Dispatch, getState: () => RootState ) => {
    dispatch(setOverseaWarehouseLoading(true));
    const user = getState().currentUser.currentUser;
  if (user) {
    axios.get(`${SERVER_ROUTES.HWC_CLIENT}/overseawarehouse`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
    }).then(response => {
        dispatch(setOverseaWarehouse(response.data));
    }).catch(error => {
        errorHandler(error, dispatch);
    }).finally(() => {
        dispatch(setOverseaWarehouseLoading(false));
    });
}else {
    dispatch(setOverseaWarehouseLoading(false));
}

};

export const selectOverseaWarehouse = (state: RootState) => state.overseaWarehouse.overseaWarehouse;
export const selectOverseaWarehouseLoading = (state: RootState) => state.overseaWarehouse.loading;

export default overseawarehouseSlice.reducer;