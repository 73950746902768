import { Address } from '../../custom_types/address-page';
import { SystemCarrier } from '../../custom_types/carrier-page';
import { User, UserResponse } from '../../custom_types/profile-page';
import {
  LabelsPageData,
  PackagesUnitSettings
} from '../../custom_types/redux-types';

const convertUserResponse = (data: UserResponse) => {
  const user: User = {
    id: data.id,
    name: data.name,
    userGroup: data.userGroup,
    company: data.company,
    email: data.email,
    phone: data.phone,
    currency: data.currency,
    role: data.role,
    token: data.token,
    token_type: data.token_type,
    tokenExpire: data.tokenExpire,
    active: data.active,
    warehouseRef: data.warehouseRef
  };

  // const printFormat = data.printFormat;
  // const labelpageData: LabelsPageData = {
  //   id: printFormat._id,
  //   labelSettings: printFormat.labelFormat,
  //   packSlipSettings: printFormat.packSlipFormat
  // };

  // const packageUnits = data.packageUnits;
  // const packagesUnitSettings: PackagesUnitSettings = {
  //   id: packageUnits._id,
  //   weightUnit: packageUnits.weightUnit,
  //   distanceUnit: packageUnits.distanceUnit
  // };

  // // TODO: carrier setting data
  // const carrierSettings: SystemCarrier[] = [];
  // const addresses: Address[] = data.addresses || [];

  return {
    user,
    // labelpageData,
    // packagesUnitSettings,
    // carrierSettings,
    // addresses
  };
};

export default convertUserResponse;
