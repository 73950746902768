import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Descriptions, Divider, Form, Input, Space, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import validateLib from 'validator';
import {
  selectCurUser,
  selectProfilePageLoading,
  updateUserPasswordHandler
} from '../../../redux/user/userSlice';
import PasswordDescription from '../../../shared/components/PasswordDescription';

const HwcClientUserProfilePanel = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();
  const user = useSelector(selectCurUser);
  const loading = useSelector(selectProfilePageLoading);

  const changePasswordHandler = () => {
    form.validateFields().then((values) => {
      if (user) {
        dispatch(updateUserPasswordHandler({ id: user.id, ...values }));
      }
    });
  };

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="用户名">{user?.name}</Descriptions.Item>
        <Descriptions.Item label="电话">{user?.phone}</Descriptions.Item>
        <Descriptions.Item label="邮箱">{user?.email}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form layout="vertical" form={form} style={{ maxWidth: 400 }}>
        <Form.Item
          label={<FormattedMessage id="curPassword" />}
          name="password"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="needPassword" />
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={
            <Space size="small">
              <FormattedMessage id="newPassword" />
              <Tooltip placement="topLeft" title={PasswordDescription}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          }
          name="newPassword"
          rules={[
            () => ({
              validator(rule, value) {
                if (!value || validateLib.isStrongPassword(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({ id: 'weekPassword' })));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="confirmPassword" />}
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({ id: 'passNotMatch' })));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ float: 'left' }}
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={changePasswordHandler}
          >
            <FormattedMessage id="修改密码" />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default HwcClientUserProfilePanel;
