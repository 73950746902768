import { PageHeader } from '@ant-design/pro-layout';
import { Button, Select, Space, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SyncOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';
import {
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses
} from '../../../redux/hwc/hwcProductSlice';
import {
  fetchHwcTouChengsHandler,
  selectHwcTouChengLoading,
  selectHwcTouChengs,
  selectShowTouChengDetailModal,
  setShowTouChengDetailModal
} from '../../../redux/hwc/hwcTouChengSlice';
import { HwcTouCheng } from '../../../custom_types/hwc/hwc.toucheng';
import HwcTouchengDetailModal from '../components/HwcTouChengDetailModal';

const HwcTouchengPage = () => {
  const dispatch = useDispatch();
  const hwcTouChengs = useSelector(selectHwcTouChengs);
  const loading = useSelector(selectHwcTouChengLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);
  const [touchengDetail, setTouchengDetail] = React.useState<HwcTouCheng | null>(null);
  const showTouChengDetailModal = useSelector(selectShowTouChengDetailModal);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    dispatch(fetchHwcTouChengsHandler(hwcClientWarehouseList[0].id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);
      dispatch(fetchHwcTouChengsHandler(clientWarehouse.id));
    }
  };

  const touchengDetailHandler = (record: HwcTouCheng) => {
    console.log(record);
    dispatch(setShowTouChengDetailModal(true));
    setTouchengDetail(record);
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcTouCheng, index: number) => index + 1
    },
    {
      title: '参考编号',
      key: 'waybill',
      dataIndex: 'waybill'
    },
    {
      title: '箱数',
      key: 'boxNum',
      render: (_: any, record: HwcTouCheng) => {
        const boxSet = new Set<string>();
        record.order_products.forEach((item) => {
          boxSet.add(item.zxh);
        });
        return boxSet.size;
      }
    },
    {
      title: '已入箱数',
      key: 'inboxNum',
      render: (_: any, record: HwcTouCheng) => {
        const inBoxes = record.order_products.filter((item) => item.status_code === '4');
        if (inBoxes.length > 0) {
          const inBoxSet = new Set<string>();
          inBoxes.forEach((item) => {
            inBoxSet.add(item.zxh);
          });
          return inBoxSet.size;
        }
        return 0;
      }
    },
    {
      title: '重量',
      key: 'weight',
      render: (_: any, record: HwcTouCheng) => {
        let weight = 0;
        record.order_products.forEach((item) => {
          weight += parseFloat(item.weight);
        });
        return weight.toFixed(2);
      }
    },
    {
      title: '目的仓库',
      key: 'mdck',
      dataIndex: 'mdck'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (text: string) => {
        switch (text) {
          case '0':
            return '待确认';
          case '1':
            return '头程待入库';
          case '2':
            return '头程已入库';
          case '3':
            return '海外仓待入库';
          case '4':
            return '海外仓已入库';
          case '5':
            return '异常订单';
          case '6':
            return '确认未通过';
          case '7':
            return '回收站';
          default:
            return '-';
        }
      }
    },
    {
      title: '添加时间',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => {
        return (
          <>
            <div>{dayjs(createdAt).format('YYYY-MM-DD')}</div>
            <div>{dayjs(createdAt).format('HH:mm:dd')}</div>
          </>
        );
      }
    },
    {
      title: '备注',
      key: 'beizhu',
      dataIndex: 'beizhu'
    },
    {
      title: '操作',
      key: 'operations',
      render: (_: any, record: HwcTouCheng) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              touchengDetailHandler(record);
            }}
          >
            详情
          </Button>
          {record.pdf_url && (
            <Button
              type="link"
              onClick={() => {
                window.open(record.pdf_url || '', '_blank');
              }}
            >
              打印
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <div>
      {showTouChengDetailModal && touchengDetail && (
        <HwcTouchengDetailModal hwcTouCheng={touchengDetail} />
      )}
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Space size="middle">
              <span>仓库名称：</span>
              <Select
                defaultValue={selectHwcClientWarehouse.id}
                style={{ width: 200 }}
                options={hwcClientWarehouseList.map((item) => ({
                  label: item.name,
                  value: item.id
                }))}
                onChange={handleHwcClientWarehouseChange}
              />
            </Space>
          )
        }
        extra={[
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() => dispatch(fetchHwcTouChengsHandler(selectHwcClientWarehouse!.id))}
          >
            刷新头程
          </Button>
        ]}
      />
      <Table<HwcTouCheng>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={hwcTouChengs}
        loading={loading}
      />
    </div>
  );
};

export default HwcTouchengPage;
