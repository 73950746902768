import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState, WarehouseOrderState } from "../../custom_types/redux-types";
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from "../../shared/utils/constants";
import { FulfillMent, IService, LineItem, WarehouseCarrier, WarehouseOrder } from "../../custom_types/warehouse-order-page";
import errorHandler from "../../shared/components/errorHandler";

const initialState: WarehouseOrderState = {
    warehouseOrders: [],
    warehouseCarriers: [],
    loading: false,
    orderNoteLoading: false
};

export const warehouseOrdersSlice = createSlice({
    name: 'warehouseOrders',
    initialState,
    reducers: {
        setWarehouseOrders: (state, action) => {
            state.warehouseOrders = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setOrderNoteLoading: (state, action) => {
            state.orderNoteLoading = action.payload;
        },
        setWarehouseCarriers: (state, action: PayloadAction<WarehouseCarrier[]>) => {
            state.warehouseCarriers = action.payload;
        }
    },
});

export const { setWarehouseOrders, setLoading, setOrderNoteLoading, setWarehouseCarriers } = warehouseOrdersSlice.actions;

export const fetchWarehouseOrders = (filter: Record<string, string | undefined>): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        const warehouseId = user.warehouseRef;
        axios
            .get(`${SERVER_ROUTES.WAREHOUSE_ORDERS}/${warehouseId}`, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                },
                params: filter
            })
            .then((response) => {
                const warehouseOrders = response.data;
                dispatch(setWarehouseOrders(warehouseOrders));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }
};

export const addOrderNote = (orderId: string, note: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setOrderNoteLoading(true));
        axios
            .put(`${SERVER_ROUTES.WAREHOUSE_ORDERS}/orderNote`, {id: orderId, description: note }, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                }
            })
            .then(() => {
                dispatch(fetchWarehouseOrders({}));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setOrderNoteLoading(false));
            });
    }
}

export const updateFulfillments = (data: { id: string, newList: FulfillMent[] }): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios
            .put(`${SERVER_ROUTES.WAREHOUSE_ORDERS}/splitFulfillment`, data, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                }
            })
            .then(() => {
                dispatch(fetchWarehouseOrders({}));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }
}

export const fulfillItems = (data: {orderId: string, index: number, fulfillment: FulfillMent}): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios
            .put(`${SERVER_ROUTES.WAREHOUSE_ORDERS}/fulfillItems`, data, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                }
            })
            .then(() => {
                dispatch(fetchWarehouseOrders({}));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }
}

export const labelFulfillment = (data: {warehouseCarrier: WarehouseCarrier, service: IService, orderId: string, index: number, fulfillment: FulfillMent}): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios
            .post(`${SERVER_ROUTES.WAREHOUSE_ORDERS}/createLabel`, data, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                }
            })
            .then(() => {
                dispatch(fetchWarehouseOrders({}));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }
}

export const fetchWarehouseCarriers = (): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser; 
    if (user) {
        dispatch(setLoading(true));
        const warehouseId = user.warehouseRef;
        axios
            .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSE_CARRIERS}${SERVER_ROUTES.WAREHOUSE}/${warehouseId}`, {
                headers: {
                    Authorization: `${user.token_type} ${user.token}`
                }
            })
            .then((response) => {
                const warehouseCarriers = response.data;
                dispatch(setWarehouseCarriers(warehouseCarriers));
            })
            .catch((error) => {
                errorHandler(error, dispatch);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }
}

export const selectWarehouseOrders = (state: RootState): WarehouseOrder[] => state.warehouseOrders.warehouseOrders;
export const selectLoading = (state: RootState): boolean => state.warehouseOrders.loading;
export const selectOrderNoteLoading = (state: RootState): boolean => state.warehouseOrders.orderNoteLoading;
export const selectWarehouseCarriers = (state: RootState): WarehouseCarrier[] => state.warehouseOrders.warehouseCarriers;

export default warehouseOrdersSlice.reducer;

