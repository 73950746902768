import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UI_ROUTES } from '../../../shared/utils/constants';
import OverseaWarehousePage from '../../OverseaWarehouse/pages/OverseaWarehousePage';
import HwcProductPage from '../../OverseaWarehouse/pages/HwcProductPage';
import HwcProductCreatePage from '../../OverseaWarehouse/pages/HwcProductCreatePage';
import HwcTouchengPage from '../../OverseaWarehouse/pages/HwcTouchengPage';
import HwcTouchengCreatePage from '../../OverseaWarehouse/pages/HwcTouchengCreatePage';
import HwcFulfillOrderPage from '../../OverseaWarehouse/pages/HwcFulfillOrderPage';
import HwcFulfillOrderCreatePage from '../../OverseaWarehouse/pages/HwcFulfillOrderCreatePage';
import HwcStockPage from '../../OverseaWarehouse/pages/HwcStockPage';

const HwcRoutes = () => {
  return (
    <Switch>
      <Route path={UI_ROUTES.HWC_USER_INFO} component={OverseaWarehousePage} />
      <Route path={UI_ROUTES.HWC_PRODUCT} component={HwcProductPage} />
      <Route path={UI_ROUTES.HWC_PRODUCT_CREATE} component={HwcProductCreatePage} />
      <Route path={UI_ROUTES.HWC_TOUCHENG} component={HwcTouchengPage} />
      <Route path={UI_ROUTES.HWC_TOUCHENG_CREATE} component={HwcTouchengCreatePage} />
      <Route path={UI_ROUTES.HWC_FULFILL_ORDER} component={HwcFulfillOrderPage} />
      <Route path={UI_ROUTES.HWC_FULFILL_ORDER_CREATE} component={HwcFulfillOrderCreatePage} />
      <Route path={UI_ROUTES.HWC_STOCK} component={HwcStockPage} />
      <Redirect from="/" to={UI_ROUTES.HWC_USER_INFO} />
    </Switch>
  );
};

export default HwcRoutes;
