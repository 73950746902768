import { PageHeader } from '@ant-design/pro-layout';
import { Button, Checkbox, Divider, Form, Input, Row, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHwcProductHandler,
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses,
  selectHwcProductLoading
} from '../../../redux/hwc/hwcProductSlice';
import { HwcProductCreateObj } from '../../../custom_types/hwc/hwc.product';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';

const HwcProductCreatePage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectHwcProductLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    form.resetFields();
  }, [form, dispatch]);

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        const data: HwcProductCreateObj = {
          ck_sku: values.ck_sku,
          pt_sku: values.pt_sku,
          pname: values.pname,
          pname_en: values.pname_en,
          hsnums: values.hsnums,
          pvalue: values.pvalue,
          country: values.country,
          description: values.description,
          weight: values.weight,
          length: values.length,
          width: values.width,
          height: values.height,
          colour: values.colour,
          img_web: values.img_web,
          dc: values.dc,
          clientWarehouseRef: selectHwcClientWarehouse.id,
          clientRef: ''
        };
        dispatch(createHwcProductHandler(data));
      })
      .finally(() => {
        form.resetFields();
      });
  };

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);
    }
  };

  return (
    <div>
      <PageHeader title="添加商品" />
      <Divider />
      {selectHwcClientWarehouse && (
        <Space size="middle">
          <span>仓库名称：</span>
          <Select
            defaultValue={selectHwcClientWarehouse.id}
            style={{ width: 200 }}
            options={hwcClientWarehouseList.map((item) => ({
              label: item.name,
              value: item.id
            }))}
            onChange={handleHwcClientWarehouseChange}
          />
        </Space>
      )}
      <Form form={form}>
        <div style={{ color: 'grey', width: 250 }}>
          SKU只能数字、字母、下划线、中横线,SKU最大长度不能超过30个字符.
        </div>
        <Row>
          <Space size="large">
            <Form.Item
              label="商品SKU"
              name="ck_sku"
              rules={[{ required: true, message: '请输入SKU' }]}
            >
              <Input placeholder="请输入商品SKU" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item label="平台SKU" name="pt_sku">
              <Input placeholder="请输入平台SKU" style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="商品名称"
              name="pname"
              rules={[{ required: true, message: '请输入商品名称' }]}
            >
              <Input placeholder="请输入商品名称" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="英文名称"
              name="pname_en"
              rules={[{ required: true, message: '请输入英文名称' }]}
            >
              <Input placeholder="请输入英文名称" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="海关编码" name="hsnums">
              <Input placeholder="请输入海关编码" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="申报价值" name="pvalue">
              <Input placeholder="请输入申报价值" style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="商品产地" name="country">
              <Input placeholder="请输入商品产地" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="商品描述" name="description">
              <Input placeholder="请输入商品描述" style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="商品颜色" name="colour">
              <Input placeholder="请输入商品颜色" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="是否带电" name="dc" initialValue="0">
              <Select
                style={{ width: 300 }}
                placeholder="请选择含电池"
                options={[
                  { label: '不含电池', value: '0' },
                  { label: '含电池', value: '1' }
                ]}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="重量(lb)"
              name="weight"
              rules={[{ required: true, message: '请输入重量' }]}
            >
              <Input placeholder="重量" style={{ width: 150 }} />
            </Form.Item>
            <Space size="small">
              <Form.Item
                label="长(in)"
                name="length"
                rules={[{ required: true, message: '请输入长' }]}
              >
                <Input placeholder="长" style={{ width: 100 }} />
              </Form.Item>
              <Form.Item
                label="宽(in)"
                name="width"
                rules={[{ required: true, message: '请输入宽' }]}
              >
                <Input placeholder="宽" style={{ width: 100 }} />
              </Form.Item>
              <Form.Item
                label="高(in)"
                name="height"
                rules={[{ required: true, message: '请输入高' }]}
              >
                <Input placeholder="高" style={{ width: 100 }} />
              </Form.Item>
            </Space>
          </Space>
        </Row>
        <Row>
          <Form.Item label="图片网络URL" name="img_web">
            <Input placeholder="图片网络URL" style={{ width: 650 }} />
          </Form.Item>
        </Row>
      </Form>
      <Space>
        <Button type="primary" onClick={okClickedHandler} loading={loading}>
          保存
        </Button>
        <Button onClick={() => form.resetFields()} disabled={loading}>
          清楚
        </Button>
      </Space>
    </div>
  );
};

export default HwcProductCreatePage;
