import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {
  createHwcTouChengHandler,
  fetchHwcTouChengProductsHandler,
  selectHwcTouChengLoading,
  selectShowTouChengProductModal,
  setShowTouChengProductModal
} from '../../../redux/hwc/hwcTouChengSlice';
import { selectHwcClientWarehouses } from '../../../redux/hwc/hwcProductSlice';
import { HwcClientWarehouse, HwcTouChengProductData } from '../../../custom_types/redux-types';
import { HwcTouChengOrder, HwcTouChengOrderProduct } from '../../../custom_types/hwc/hwc.toucheng';
import { Country, COUNTRY_NAMES } from '../../../shared/utils/constants';
import HwcTouChengProductModal from '../components/HwcTouChengProductModal';

const HwcTouchengCreatePage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectHwcTouChengLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const showTouChengProductModal = useSelector(selectShowTouChengProductModal);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);
  const [orderProducts, setOrderProducts] = React.useState<HwcTouChengOrderProduct[]>([]);

  useEffect(() => {
    dispatch(fetchHwcTouChengProductsHandler(hwcClientWarehouseList[0].id));
    form.resetFields();
  }, [form, dispatch, hwcClientWarehouseList]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      dispatch(fetchHwcTouChengProductsHandler(clientWarehouse.id));
      setSelectHwcClientWarehouse(clientWarehouse);
    }
  };

  const addOrderProductHandler = () => {
    dispatch(setShowTouChengProductModal(true));
  };

  const closeTouChengProductModal = () => {
    dispatch(setShowTouChengProductModal(false));
  };

  const modalChangeHandler = (products: HwcTouChengProductData[]) => {
    const newList = [...orderProducts];
    products.forEach((product) => {
      const convertedProduct: HwcTouChengOrderProduct = {
        ck_sku: product.ck_sku,
        nums: product.nums,
        zxh: product.zxh,
        weight: product.weight?.toFixed(2) || '0',
        length: product.length?.toFixed(2) || '0',
        width: product.width?.toFixed(2) || '0',
        height: product.height?.toFixed(2) || '0'
      };
      newList.push(convertedProduct);
    });
    setOrderProducts(newList);
    dispatch(setShowTouChengProductModal(false));
  };

  const removeProductItem = (index: number) => {
    const newList = [...orderProducts];
    newList.splice(index, 1);
    setOrderProducts(newList);
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        const data: HwcTouChengOrder = {
          waybill: '', // 参考单号
          mdck: selectHwcClientWarehouse.code, // 目的仓库
          beizhu: values.beizhu, // 备注
          country: values.country, // 寄往国家 美国
          order_products: orderProducts
        };
        console.log(data);
        if (data.mdck && data.order_products.length > 0) {
          dispatch(createHwcTouChengHandler(data, selectHwcClientWarehouse.id));
        } else {
          message.error('请填写完整信息');
        }
      })
      .catch((error) => {
        // console.log(error.errorFields[0].errors[0]);
        message.error(error.errorFields[0].errors[0]);
      })
      .finally(() => {
        form.resetFields();
        setOrderProducts([]);
      });
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcTouChengOrderProduct, index: number) => index + 1
    },
    {
      title: '装箱号',
      key: 'zxh',
      dataIndex: 'zxh'
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '数量',
      key: 'nums',
      dataIndex: 'nums'
    },
    {
      title: '重量',
      key: 'weight',
      dataIndex: 'weight'
    },
    {
      title: '长',
      key: 'length',
      dataIndex: 'length'
    },
    {
      title: '宽',
      key: 'width',
      dataIndex: 'width'
    },
    {
      title: '高',
      key: 'height',
      dataIndex: 'height'
    }
  ];

  return (
    <div>
      {showTouChengProductModal && selectHwcClientWarehouse && (
        <HwcTouChengProductModal
          onCancel={closeTouChengProductModal}
          onChange={modalChangeHandler}
        />
      )}
      <PageHeader title="新建入库" />
      <Divider />
      {selectHwcClientWarehouse && (
        <Space size="middle" style={{ marginBottom: 20 }}>
          <span>
            <span style={{ color: 'red' }}>*</span>目的仓库：
          </span>
          <Select
            defaultValue={selectHwcClientWarehouse.id}
            style={{ width: 200 }}
            options={hwcClientWarehouseList.map((item) => ({
              label: item.name,
              value: item.id
            }))}
            onChange={handleHwcClientWarehouseChange}
          />
        </Space>
      )}
      <Form form={form}>
        <Row>
          <Space size="large">
            <Form.Item
              label="目的国家"
              name="country"
              initialValue={Country.USA}
              rules={[{ required: true, message: '请选择目的国家' }]}
            >
              <Select
                style={{ width: 300 }}
                placeholder="请选择目的国家"
                options={[{ label: COUNTRY_NAMES[Country.USA], value: Country.USA }]}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="订单备注" name="beizhu">
              <Input placeholder="请输入备注" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
      </Form>
      <Divider />
      <Button
        type="primary"
        onClick={addOrderProductHandler}
        icon={<PlusOutlined />}
        disabled={selectHwcClientWarehouse === undefined}
      >
        添加明细
      </Button>
      {orderProducts.length > 0 ? (
        <Table<HwcTouChengOrderProduct>
          rowKey={(record) => record.ck_sku}
          columns={[
            ...columns,
            {
              title: '操作',
              key: 'operations',
              render: (_, __, index: number) => (
                <Button type="link" onClick={() => removeProductItem(index)}>
                  删除
                </Button>
              )
            }
          ]}
          dataSource={orderProducts}
        />
      ) : (
        <Empty
          style={{ width: '90%', height: '90%' }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{ height: 60 }}
          description={<Typography.Text>还没添加商品，点击添加明细按钮添加商品</Typography.Text>}
        >
          <Button
            type="primary"
            onClick={addOrderProductHandler}
            disabled={selectHwcClientWarehouse === undefined}
          >
            现在添加
          </Button>
        </Empty>
      )}
      <Divider />
      <Space>
        <Button type="primary" onClick={okClickedHandler} loading={loading}>
          保存
        </Button>
        <Button
          onClick={() => {
            form.resetFields();
            setOrderProducts([]);
          }}
          disabled={loading}
        >
          清除
        </Button>
      </Space>
    </div>
  );
};

export default HwcTouchengCreatePage;
