import { SyncOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Select, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';
import {
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses
} from '../../../redux/hwc/hwcProductSlice';
import { HwcStock } from '../../../custom_types/hwc/hwc.stock';
import {
  fetchHwcStocksHandler,
  selectHwcStocks,
  selectHwcStocksLoading
} from '../../../redux/hwc/hwcStockSlice';

const HwcStockPage = () => {
  const dispatch = useDispatch();
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);
  const userHwcStocks = useSelector(selectHwcStocks);
  const loading = useSelector(selectHwcStocksLoading);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    if (hwcClientWarehouseList.length > 0) {
      dispatch(fetchHwcStocksHandler(hwcClientWarehouseList[0].code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);
      dispatch(fetchHwcStocksHandler(clientWarehouse.code));
    }
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '产品名称',
      key: 'pname',
      dataIndex: 'pname'
    },
    {
      title: '现有库存',
      key: 'stock',
      dataIndex: 'stock'
    }
  ];

  return (
    <div>
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Select
              defaultValue={selectHwcClientWarehouse.code}
              style={{ width: 200 }}
              options={hwcClientWarehouseList.map((item) => ({
                label: item.name,
                value: item.code
              }))}
              onChange={handleHwcClientWarehouseChange}
            />
          )
        }
        extra={[
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() => dispatch(fetchHwcStocksHandler(selectHwcClientWarehouse.code))}
          >
            同步库存
          </Button>
        ]}
      />
      <Table<HwcStock>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={userHwcStocks}
        loading={loading}
      />
    </div>
  );
};

export default HwcStockPage;
