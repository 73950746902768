import { InputNumber, message, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHwcFulfillOrdersLoading,
  selectHwcFulfillStocks,
  selectHwcShowFulfillStockModal
} from '../../../redux/hwc/hwcFulfillOrderSlice';
import { HwcStock } from '../../../custom_types/hwc/hwc.stock';
import { HwcFulFillProduct } from '../../../custom_types/hwc/hwc.fulfillorder';

interface HwcFulfillStocksModalProps {
  onCancel: () => void;
  onChange: (products: HwcFulFillProduct[]) => void;
}

const HwcFulfillStocksModal = ({ onCancel, onChange }: HwcFulfillStocksModalProps) => {
  const dispatch = useDispatch();
  const showFulfillStocksModal = useSelector(selectHwcShowFulfillStockModal);
  const [dataSource, setDataSource] = useState<HwcFulFillProduct[]>([]);
  const [selectedData, setSelectedData] = useState<HwcFulFillProduct[]>([]);
  const loading = useSelector(selectHwcFulfillOrdersLoading);
  const stocks = useSelector(selectHwcFulfillStocks);

  useEffect(() => {
    setDataSource(stocks.map((item: HwcStock) => ({ sku: item.ck_sku, nums: 0 })));
  }, [dispatch, stocks]);

  const validateData = (data: HwcFulFillProduct[]): { flag: boolean; msg: string } => {
    const invalidItem = data.find((item) => item.nums === 0);

    if (invalidItem) {
      if (invalidItem.nums === 0) {
        return { flag: false, msg: `${invalidItem.sku} - 数量不能为空` };
      }
    }

    return { flag: true, msg: '' };
  };

  const onOKHandler = () => {
    // console.log('onOKHandler');
    if (selectedData.length === 0) {
      message.error('请选择商品');
    } else {
      const result = validateData(selectedData);
      if (result.flag) {
        onChange(selectedData);
      } else {
        message.error(result.msg);
      }
    }
  };

  const updateDataSource = (row: HwcFulFillProduct) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.sku === item.sku);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: '商品编码',
      key: 'sku',
      dataIndex: 'sku'
    },
    {
      title: '数量',
      key: 'nums',
      dataIndex: 'nums',
      render: (_: any, record: HwcFulFillProduct) => (
        <InputNumber
          min={0}
          placeholder="数量"
          onChange={(value: number | null) => {
            console.log(value);
            record.nums = value === null ? 0 : value;
            updateDataSource(record);
          }}
          defaultValue={0}
        />
      )
    }
  ];

  return (
    <Modal
      closable={false}
      title={<div>添加商品</div>}
      cancelText="取消"
      onCancel={onCancel}
      okText="确定"
      okButtonProps={{ loading }}
      onOk={onOKHandler}
      open={showFulfillStocksModal}
      width={1500}
    >
      <Table<HwcFulFillProduct>
        rowKey={(record) => record.sku}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 500 }}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            // console.log(selectedRows);
            setSelectedData(selectedRows);
          }
        }}
      />
    </Modal>
  );
};

export default HwcFulfillStocksModal;
