import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { HwcBill, HwcBillState } from '../../custom_types/hwc/hwc.bill';
import axios from '../../shared/utils/axios.base';
import { AppThunk, RootState } from '../../custom_types/redux-types';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const initialState: HwcBillState = {
    hwcBills: [],
    loading: false
};

const hwcBillSlice = createSlice({
    name: 'hwcBill',
    initialState,
    reducers: {
        setHwcBills: (state, action: PayloadAction<HwcBill[]>) => {
            state.hwcBills = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const { setHwcBills, setLoading } = hwcBillSlice.actions;

export const fetchHwcBillsHandler = (): AppThunk => async (dispatch: Dispatch,  getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
    dispatch(setLoading(true));
    axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_BILL}`, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const hwcBills: HwcBill[] = response.data;
            dispatch(setHwcBills(hwcBills));
        })
        .catch(error => {
            console.error(error);
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const selectHwcBills = (state: RootState) => state.hwcBill.hwcBills;
export const selectHwcBillsLoading = (state: RootState) => state.hwcBill.loading;

export default hwcBillSlice.reducer;