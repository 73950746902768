import {
  BankOutlined,
  LockTwoTone,
  MailOutlined,
  QuestionCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import validateLib from 'validator';
import { UserRegisterData } from '../../../custom_types/profile-page';
import {
  // registerUserHandler,
  selectLoginLoading,
  selectRegisterError,
  setLoginError,
  setRegisterError,
  setResetEmailSent
} from '../../../redux/user/userSlice';
import PasswordDescription from '../../../shared/components/PasswordDescription';
import { REST_ERROR_CODE, UI_ROUTES } from '../../../shared/utils/constants';
import LoginAlert from '../components/LoginAlert';
import LoginPageFrame from '../components/LoginPageFrame';

const RegisterPage = (): ReactElement => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const loginLoading = useSelector(selectLoginLoading);
  const registerError = useSelector(selectRegisterError);

  useEffect(() => {
    dispatch(setRegisterError(undefined));
    dispatch(setResetEmailSent(false));
    dispatch(setLoginError(false));
  }, [dispatch]);

  const onFormSubmit = (values: UserRegisterData) => {
    // dispatch(registerUserHandler(values));
  };

  return (
    <LoginPageFrame>
      <>
        {registerError && <LoginAlert content={REST_ERROR_CODE[registerError]} />}
        <Form onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <>
                    <FormattedMessage id="name" />
                    <FormattedMessage id="requiredField" />
                  </>
                )
              }
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="prefixIcon" />}
              placeholder={intl.formatMessage({ id: 'name' })}
              allowClear
            />
          </Form.Item>
          <Form.Item name="company">
            <Input
              size="large"
              prefix={<BankOutlined className="prefixIcon" />}
              placeholder={intl.formatMessage({ id: 'company' })}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: <FormattedMessage id="wrong_email_format" />
              },
              { required: true, message: <FormattedMessage id="enter_email" /> }
            ]}
          >
            <Input
              size="large"
              prefix={<MailOutlined className="prefixIcon" />}
              placeholder={intl.formatMessage({ id: 'enter_email' })}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: <FormattedMessage id="enter_pass" /> },
              () => ({
                validator(rule, value) {
                  if (!value || validateLib.isStrongPassword(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'weekPassword' })));
                }
              })
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockTwoTone className="prefixIcon" />}
              placeholder={intl.formatMessage({ id: 'enter_pass' })}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: <FormattedMessage id="enter_pass" /> },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'passNotMatch' })));
                }
              })
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockTwoTone className="prefixIcon" />}
              placeholder={intl.formatMessage({ id: 'confirm_pass' })}
            />
          </Form.Item>
          <Form.Item>
            <FormattedMessage id="use_strong_pass" />{' '}
            <Tooltip placement="topLeft" title={PasswordDescription}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Form.Item>
          <Form.Item style={{ marginBottom: '10px' }}>
            <Button
              htmlType="submit"
              style={{ width: '100%' }}
              type="primary"
              size="large"
              loading={loginLoading}
            >
              <FormattedMessage id="create_account" />
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <FormattedMessage id="already_has_account" />?{' '}
            <Link to={UI_ROUTES.LOGIN}>
              <FormattedMessage id="sign_in" />
            </Link>
          </Form.Item>
        </Form>
      </>
    </LoginPageFrame>
  );
};

export default RegisterPage;
