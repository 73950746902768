import { Switch, Table, Tabs, Tag } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UI_ROUTES } from '../../../shared/utils/constants';
import {
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses
} from '../../../redux/hwc/hwcProductSlice';
import { HwcClientWarehouse, HwcClientWarehouseChannel } from '../../../custom_types/redux-types';
import OverseaWarehouseHeader from '../components/OverseaWarehouseHeader';
import { getOverseaWarehouse } from '../../../redux/user/overseawarehouseSlice';
import HwcClientUserProfilePanel from '../components/HwcClientUserProfilePanel';
import HwcClientBillingPanel from '../components/HwcClientBillingPanel';

const { TabPane } = Tabs;

const OverseaWarehousePage = (): ReactElement => {
  const dispatch = useDispatch();
  const hwcClientWarehouses = useSelector(selectHwcClientWarehouses);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    dispatch(getOverseaWarehouse());
  }, [dispatch]);

  const warehouseColumns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcClientWarehouse, index: number) => index + 1
    },
    {
      title: '仓库名称',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '仓库代码',
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: '状态',
      dataIndex: 'active',
      key: 'active',
      render: (value: boolean, record: HwcClientWarehouse) =>
        value ? <Tag color="green">启用</Tag> : <Tag color="red">停用</Tag>
    },
    {
      title: '物流渠道',
      key: 'channels',
      render: (channels: HwcClientWarehouseChannel[], record: HwcClientWarehouse) => {
        return record.channels.map((channel) => <div>{channel.name}</div>);
      }
    }
  ];

  return (
    <OverseaWarehouseHeader
      footer={
        <Tabs defaultActiveKey="1">
          <TabPane key="1" tab="用户信息">
            <HwcClientUserProfilePanel />
          </TabPane>
          <TabPane key="2" tab="用户仓库">
            <Table<HwcClientWarehouse>
              rowKey="id"
              dataSource={hwcClientWarehouses}
              columns={warehouseColumns}
            />
          </TabPane>
          <TabPane key="3" tab="账单信息">
            <HwcClientBillingPanel />
          </TabPane>
        </Tabs>
      }
    />
  );
};

export default OverseaWarehousePage;
