import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag, Row, Col, Descriptions, Statistic, Space, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useDispatch, useSelector } from 'react-redux';
import { SyncOutlined } from '@ant-design/icons';
import { UI_ROUTES } from '../../../shared/utils/constants';
import {
  getOverseaWarehouse,
  selectOverseaWarehouse,
  selectOverseaWarehouseLoading
} from '../../../redux/user/overseawarehouseSlice';

interface OverseaWarehouseHeaderProps {
  footer: ReactElement;
}

const OverseaWarehouseHeader = ({ footer }: OverseaWarehouseHeaderProps) => {
  const dispatch = useDispatch();
  const overseaWarehouse = useSelector(selectOverseaWarehouse);
  const loading = useSelector(selectOverseaWarehouseLoading);

  return (
    <PageHeader
      title="概况"
      tags={
        overseaWarehouse && (
          <>
            <Tag color={overseaWarehouse.active ? 'blue' : 'default'}>
              {overseaWarehouse.active ? '启用' : '停用'}
            </Tag>
            <Button
              type="default"
              loading={loading}
              icon={<SyncOutlined />}
              onClick={() => dispatch(getOverseaWarehouse())}
            />
          </>
        )
      }
      footer={footer}
    >
      {overseaWarehouse && (
        <Row>
          <Col span={12}>
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="客户名称">{overseaWarehouse.name}</Descriptions.Item>
              {overseaWarehouse.company && (
                <Descriptions.Item label="公司名称">{overseaWarehouse.company}</Descriptions.Item>
              )}
              <Descriptions.Item label="电话">{overseaWarehouse.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="邮箱">{overseaWarehouse.email}</Descriptions.Item>
              <Descriptions.Item label="地址">{`${overseaWarehouse.address1}, ${
                overseaWarehouse.address2 ? overseaWarehouse.address2 : ''
              } ${overseaWarehouse.city} ${overseaWarehouse.state} ${overseaWarehouse.country} ${
                overseaWarehouse.zip
              }`}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Space size="large">
              <Statistic title="可用余额 (USD)" value={overseaWarehouse.balance} precision={2} />
              <Statistic title="用户押金 (USD)" value={overseaWarehouse.deposit} precision={2} />
            </Space>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default OverseaWarehouseHeader;
