import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { HwcProduct, HwcProductCreateObj } from "../../custom_types/hwc/hwc.product";
import { AppThunk, HwcClientWarehouse, HwcProductState, RootState } from "../../custom_types/redux-types";
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from "../../shared/utils/constants";
import errorHandler from "../../shared/components/errorHandler";

const initialState: HwcProductState = {
    hwcProducts: [],
    hwcClientWarehouses: [],
    loading: false
};

const hwcProductSlice = createSlice({
    name: 'hwcProduct',
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<HwcProduct[]>) => {
            state.hwcProducts = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setHwcClientWarehouses: (state, action: PayloadAction<HwcClientWarehouse[]>) => {
            state.hwcClientWarehouses = action.payload;
        }
    }
});

export const { setProducts, setLoading, setHwcClientWarehouses } = hwcProductSlice.actions;

export const fetchHwcProductsHandler = (hwcClientWarehouseId: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
      const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_PRODUCT}/${hwcClientWarehouseId}`, {
            headers: {
            Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const products: HwcProduct[] = response.data;
            dispatch(setProducts(products));
        })
        .catch(error => {
            errorHandler(error, dispatch);
            message.error('获取商品失败');
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const createHwcProductHandler = (data: HwcProductCreateObj): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.post(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_PRODUCT}`, data, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            dispatch(fetchHwcProductsHandler(data.clientWarehouseRef));
            message.success('创建商品成功');
        })
        .catch(error => {
            message.error('创建商品失败');
            errorHandler(error, dispatch);
        })
        .finally(() => { 
            dispatch(setLoading(false));
        });
    }
};

export const fetchHwcClientWarehousesHandler = (): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}`, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const hwcClientWarehouses: HwcClientWarehouse[] = response.data;
            dispatch(setHwcClientWarehouses(hwcClientWarehouses));
        })
        .catch(error => {
            message.error('获取仓库失败');
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const selectHwcProducts = (state: RootState) => state.hwcProduct.hwcProducts;
export const selectHwcProductLoading = (state: RootState) => state.hwcProduct.loading;
export const selectHwcClientWarehouses = (state: RootState) => state.hwcProduct.hwcClientWarehouses;

export default hwcProductSlice.reducer;