import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, HwcStockState, RootState } from "../../custom_types/redux-types";
import { HwcStock } from "../../custom_types/hwc/hwc.stock";
import errorHandler from "../../shared/components/errorHandler";
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from "../../shared/utils/constants";

const initialState: HwcStockState = {
    hwcStocks: [],
    loading: false
};

const hwcStockSlice = createSlice({
    name: 'hwcStock',
    initialState,
    reducers: {
        setHwcStocks: (state, action: PayloadAction<HwcStock[]>) => {
            state.hwcStocks = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const { setHwcStocks, setLoading } = hwcStockSlice.actions;

export const fetchHwcStocksHandler = (code: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_STOCK}/${code}`, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const hwcStocks: HwcStock[] = response.data;
            dispatch(setHwcStocks(hwcStocks));
        })
        .catch(error => {
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const selectHwcStocks = (state: RootState) => state.hwcStock.hwcStocks;
export const selectHwcStocksLoading = (state: RootState) => state.hwcStock.loading;

export default hwcStockSlice.reducer;