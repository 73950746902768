import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { AppThunk, HwcFulfillOrderState, RootState } from "../../custom_types/redux-types";
import errorHandler from "../../shared/components/errorHandler";
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from "../../shared/utils/constants";
import { ClientFulfillOrderSearchQuery, HwcFulfillOrder, HwcFulfillOrderData } from "../../custom_types/hwc/hwc.fulfillorder";
import { HwcStock } from "../../custom_types/hwc/hwc.stock";

const initialState: HwcFulfillOrderState = {
    hwcFulfillOrders: [],
    hwcFulfillStocks: [],
    loading: false,
    showFulfillStockModal: false
};

const hwcFulfillOrderSlice = createSlice({
    name: 'hwcFulfillOrder',
    initialState,
    reducers: {
        setHwcFulfillOrders: (state, action: PayloadAction<HwcFulfillOrder[]>) => {
            state.hwcFulfillOrders = action.payload;
        },
        setHwcFulfillStocks: (state, action: PayloadAction<HwcStock[]>) => {
            state.hwcFulfillStocks = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowFulfillStockModal: (state, action: PayloadAction<boolean>) => {
            state.showFulfillStockModal = action.payload;
        }
    }
});

export const { setHwcFulfillOrders, setLoading, setShowFulfillStockModal, setHwcFulfillStocks } = hwcFulfillOrderSlice.actions;

// export const fetchHwcFulfillOrdersHandler = (code: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
//     const user = getState().currentUser.currentUser;
//     if (user) {
//         dispatch(setLoading(true));
//         axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_ORDER}/${code}`, {
//             headers: {
//                 Authorization: `${user.token_type} ${user.token}`
//             }
//         })
//         .then(response => {
//             const hwcFulfillOrders: HwcFulfillOrder[] = response.data;
//             dispatch(setHwcFulfillOrders(hwcFulfillOrders));
//         })
//         .catch(error => {
//             errorHandler(error, dispatch);
//         })
//         .finally(() => {
//             dispatch(setLoading(false));
//         });
//     }
// }

export const searchHwcClientFulfillOrdersHandler = (searchQuery: ClientFulfillOrderSearchQuery): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.post(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_ORDER}/search`, searchQuery, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const hwcFulfillOrders: HwcFulfillOrder[] = response.data;
            dispatch(setHwcFulfillOrders(hwcFulfillOrders));
        })
        .catch(error => {
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const deleteFulfillOrdersHandler = (
  hwcClientWarehouseId: string,
  orderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log(orderIds);
    dispatch(setLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_ORDER}/delete/${hwcClientWarehouseId}`,
        orderIds,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        notification.success({ message: '订单删除成功', description: '订单删除成功' });
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const revertFulfillOrdersHandler = (
  hwcClientWarehouseId: string,
  orderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log(orderIds);
    dispatch(setLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_ORDER}/revert/${hwcClientWarehouseId}`,
        orderIds,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        notification.success({ message: '订单复原完成', description: '订单复原完成' });
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const fetchFulfillStockHandler = (id: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_STOCK}/${id}`, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const stocks: HwcStock[] = response.data;
            dispatch(setHwcFulfillStocks(stocks));
        })
        .catch(error => {
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const createHwcFulfillOrderHandler = (data: HwcFulfillOrderData, id: string | undefined, form: any, setOrderProducts: any): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user && id) {
        dispatch(setLoading(true));
        axios.post(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_FULFILL_ORDER}/${id}`, data, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            form.resetFields();
            setOrderProducts([]);
        })
        .catch(error => {
            errorHandler(error, dispatch);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const selectHwcFulfillOrders = (state: RootState) => state.hwcFulfillOrder.hwcFulfillOrders;
export const selectHwcFulfillStocks = (state: RootState) => state.hwcFulfillOrder.hwcFulfillStocks;
export const selectHwcFulfillOrdersLoading = (state: RootState) => state.hwcFulfillOrder.loading;
export const selectHwcShowFulfillStockModal = (state: RootState) => state.hwcFulfillOrder.showFulfillStockModal;

export default hwcFulfillOrderSlice.reducer;