import { BarcodeOutlined, HomeOutlined, TruckFilled } from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UI_ROUTES } from '../../../shared/utils/constants';
import { selectHwcClientWarehouses } from '../../../redux/hwc/hwcProductSlice';

const OverseaWarehouseMenuList = (): ReactElement => {
  const location = useLocation();
  const hwcClientWarehouses = useSelector(selectHwcClientWarehouses);

  return (
    <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
      <Menu.Item key={UI_ROUTES.HWC_USER_INFO} icon={<HomeOutlined />}>
        <NavLink to={UI_ROUTES.HWC_USER_INFO}>
          {/* <FormattedMessage id={UI_ROUTES.OVERSEA_WAREHOUSE} /> */}
          概况
        </NavLink>
      </Menu.Item>
      {hwcClientWarehouses.length > 0 && (
        <>
          <SubMenu key="hwc_prodcuts" title="商品" icon={<BarcodeOutlined />}>
            <Menu.Item key={UI_ROUTES.HWC_PRODUCT_CREATE}>
              <NavLink to={UI_ROUTES.HWC_PRODUCT_CREATE}>添加商品</NavLink>
            </Menu.Item>
            <Menu.Item key={UI_ROUTES.HWC_PRODUCT}>
              <NavLink to={UI_ROUTES.HWC_PRODUCT}>商品管理</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="hwc_toucheng" title="头程" icon={<TruckFilled />}>
            <Menu.Item key={UI_ROUTES.HWC_TOUCHENG_CREATE}>
              <NavLink to={UI_ROUTES.HWC_TOUCHENG_CREATE}>新建入库</NavLink>
            </Menu.Item>
            <Menu.Item key={UI_ROUTES.HWC_TOUCHENG}>
              <NavLink to={UI_ROUTES.HWC_TOUCHENG}>头程订单</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="hwc_fulfill" title="代发" icon={<TruckFilled />}>
            <Menu.Item key={UI_ROUTES.HWC_FULFILL_ORDER_CREATE}>
              <NavLink to={UI_ROUTES.HWC_FULFILL_ORDER_CREATE}>新建订单</NavLink>
            </Menu.Item>
            <Menu.Item key={UI_ROUTES.HWC_FULFILL_ORDER}>
              <NavLink to={UI_ROUTES.HWC_FULFILL_ORDER}>代发订单</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="hwc_stock" title="库存" icon={<TruckFilled />}>
            <Menu.Item key={UI_ROUTES.HWC_STOCK}>
              <NavLink to={UI_ROUTES.HWC_STOCK}>查看库存</NavLink>
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  );
};

export default OverseaWarehouseMenuList;
