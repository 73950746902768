import React, { ReactElement, useEffect } from 'react';
import { Button, Select, Space, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { HwcProduct } from '../../../custom_types/hwc/hwc.product';
import {
  fetchHwcClientWarehousesHandler,
  fetchHwcProductsHandler,
  selectHwcClientWarehouses,
  selectHwcProductLoading,
  selectHwcProducts
} from '../../../redux/hwc/hwcProductSlice';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';

const HwcProductPage = (): ReactElement => {
  const dispatch = useDispatch();
  const hwcProducts = useSelector(selectHwcProducts);
  const loading = useSelector(selectHwcProductLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    if (selectHwcClientWarehouse) {
      dispatch(fetchHwcProductsHandler(selectHwcClientWarehouse.id));
    }
  }, [dispatch, selectHwcClientWarehouse]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);
      dispatch(fetchHwcProductsHandler(clientWarehouse.id));
    }
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcProduct, index: number) => index + 1
    },
    {
      title: '图片',
      key: 'img_web',
      dataIndex: 'img_web',
      render: (text: string) => {
        return text ? <img src={text} alt="img" width="100" /> : null;
      }
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '平台SKU',
      key: 'pt_sku',
      dataIndex: 'pt_sku'
    },
    {
      title: '产品名称',
      key: 'pname',
      dataIndex: 'pname'
    },
    {
      title: '重量(lb)',
      key: 'weight',
      dataIndex: 'weight'
    },
    {
      title: '尺寸(in)',
      key: 'dimention',
      dataIndex: 'dimention',
      render: (_: any, record: HwcProduct) => {
        const length = record.length ? record.length : '-';
        const width = record.width ? record.width : '-';
        const height = record.height ? record.height : '-';
        return `${length} x ${width} x ${height}`;
      }
    },
    {
      title: '颜色',
      key: 'pvalue',
      dataIndex: 'pvalue'
    },
    {
      title: '申报价格',
      key: 'colour',
      dataIndex: 'colour'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (text: string, _: HwcProduct): ReactElement => {
        switch (text) {
          case '0':
            return <div>待审核</div>;
          case '1':
            return <div>已审核</div>;
          case '2':
            return <div>已审核</div>;
          case '3':
            return <div>已审核</div>;
          default:
            return <div>-</div>;
        }
      }
    },
    {
      title: '备注',
      key: 'description',
      dataIndex: 'description'
    }
    // {
    //   title: '操作',
    //   key: 'operations',
    //   render: (text: string, record: HwcProduct): ReactElement => {
    //     return (
    //       <>
    //         <Popconfirm
    //           key="确认删除该仓库"
    //           title="确认删除该仓库?"
    //           placement="topRight"
    //           onConfirm={() =>
    //             dispatch(
    //               deleteHwcProductHandler(record.clientRef, record.clientWarehouseRef, record.id)
    //             )
    //           }
    //           okText="确定"
    //           cancelText="取消"
    //         >
    //           <Button key="删除" size="small" type="link">
    //             删除
    //           </Button>
    //         </Popconfirm>
    //       </>
    //     );
    //   }
    // }
  ];

  return (
    <div>
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Space size="middle">
              <span>仓库名称：</span>
              <Select
                defaultValue={selectHwcClientWarehouse.id}
                style={{ width: 200 }}
                options={hwcClientWarehouseList.map((item) => ({
                  label: item.name,
                  value: item.id
                }))}
                onChange={handleHwcClientWarehouseChange}
              />
            </Space>
          )
        }
        extra={[
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() => dispatch(fetchHwcProductsHandler(selectHwcClientWarehouse!.id))}
          >
            刷新商品
          </Button>
        ]}
      />
      <Table<HwcProduct>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={hwcProducts}
        loading={loading}
      />
    </div>
  );
};

export default HwcProductPage;
