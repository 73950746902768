import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AppThunk, HwcTouChengState, RootState } from "../../custom_types/redux-types";
import { HwcTouCheng, HwcTouChengOrder } from "../../custom_types/hwc/hwc.toucheng";
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from "../../shared/utils/constants";
import errorHandler from "../../shared/components/errorHandler";
import { HwcProduct } from "../../custom_types/hwc/hwc.product";

const initialState: HwcTouChengState = {
    hwcTouChengs: [],
    hwcTouChengProducts: [],
    loading: false,
    showTouChengProductModal: false,
    touChengProductModalLoading: false,
    showTouChengDetailModal: false

};

const hwcTouChengSlice = createSlice({
    name: 'hwcTouCheng',
    initialState,
    reducers: {
        setHwcTouChengs: (state, action: PayloadAction<HwcTouCheng[]>) => {
            state.hwcTouChengs = action.payload;
        },
        setHwcTouChengProducts: (state, action: PayloadAction<HwcProduct[]>) => {
            state.hwcTouChengProducts = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowTouChengProductModal: (state, action: PayloadAction<boolean>) => {
            state.showTouChengProductModal = action.payload;
        },
        setTouChengProductModalLoading: (state, action: PayloadAction<boolean>) => {
            state.touChengProductModalLoading = action.payload;
        },
        setShowTouChengDetailModal: (state, action: PayloadAction<boolean>) => {
            state.showTouChengDetailModal = action.payload;
        }
    }
});

export const { setHwcTouChengs, setHwcTouChengProducts, setLoading, setShowTouChengProductModal, setTouChengProductModalLoading, setShowTouChengDetailModal } = hwcTouChengSlice.actions;

export const fetchHwcTouChengsHandler = (hwcClientWarehouseId: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_TOUCHENG}/${hwcClientWarehouseId}`, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const hwcTouChengs: HwcTouCheng[] = response.data;
            dispatch(setHwcTouChengs(hwcTouChengs));
        })
        .catch(error => {
            errorHandler(error, dispatch);
            message.error('获取头程失败');
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const fetchHwcTouChengProductsHandler = (hwcClientWarehouseId: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
      const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios.get(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_TOUCHENG}/products/${hwcClientWarehouseId}`, {
            headers: {
            Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(response => {
            const products: HwcProduct[] = response.data;
            dispatch(setHwcTouChengProducts(products));
        })
        .catch(error => {
            errorHandler(error, dispatch);
            message.error('获取商品失败');
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const createHwcTouChengHandler = (data: HwcTouChengOrder, hwcClientWarehouseId: string): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().currentUser.currentUser;
    if (user) {
        dispatch(setLoading(true));
        axios.post(`${SERVER_ROUTES.HWC_CLIENT}${SERVER_ROUTES.HWC_TOUCHENG}`, data, {
            headers: {
                Authorization: `${user.token_type} ${user.token}`
            }
        })
        .then(() => {
            message.success('创建头程成功');
            dispatch(fetchHwcTouChengsHandler(hwcClientWarehouseId));
        })
        .catch(error => {
            errorHandler(error, dispatch);
            message.error('创建头程失败');
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    }
};

export const selectHwcTouChengs = (state: RootState) => state.hwcTouCheng.hwcTouChengs;
export const selectHwcTouChengProducts = (state: RootState) => state.hwcTouCheng.hwcTouChengProducts;
export const selectHwcTouChengLoading = (state: RootState) => state.hwcTouCheng.loading;
export const selectShowTouChengProductModal = (state: RootState) => state.hwcTouCheng.showTouChengProductModal;
export const selectTouChengProductModalLoading = (state: RootState) => state.hwcTouCheng.touChengProductModalLoading;
export const selectShowTouChengDetailModal = (state: RootState) => state.hwcTouCheng.showTouChengDetailModal;

export default hwcTouChengSlice.reducer;