import React from 'react';
import { Descriptions, Modal, Space, Table, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';
import { HwcTouCheng, HwcTouChengOrderProduct } from '../../../custom_types/hwc/hwc.toucheng';
import {
  selectShowTouChengDetailModal,
  setShowTouChengDetailModal
} from '../../../redux/hwc/hwcTouChengSlice';

interface HwcTouchengDetailModalProps {
  hwcTouCheng: HwcTouCheng;
}

const HwcTouchengDetailModal = ({ hwcTouCheng }: HwcTouchengDetailModalProps) => {
  const dispatch = useDispatch();
  const showTouChengDetailModal = useSelector(selectShowTouChengDetailModal);

  const statusHandler = (status: string) => {
    switch (status) {
      case '0':
        return '待确认';
      case '1':
        return '头程待入库';
      case '2':
        return '头程已入库';
      case '3':
        return '海外仓待入库';
      case '4':
        return '海外仓已入库';
      case '5':
        return '异常订单';
      case '6':
        return '确认未通过';
      default:
        return '-';
    }
  };

  const productColumns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcTouChengOrderProduct, index: number) => index + 1
    },
    {
      title: '箱号',
      key: 'zxh',
      dataIndex: 'zxh'
    },
    {
      title: '商品SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '单箱数量',
      key: 'nums',
      dataIndex: 'nums'
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (text: string) => statusHandler(text)
    }
  ];

  const boxColumns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcTouChengOrderProduct, index: number) => index + 1
    },
    {
      title: '箱号',
      key: 'zxh',
      dataIndex: 'zxh'
    },
    {
      title: '重量(lb)',
      key: 'weight',
      dataIndex: 'weight'
    },
    {
      title: '长(in)',
      key: 'length',
      dataIndex: 'length'
    },
    {
      title: '宽(in)',
      key: 'width',
      dataIndex: 'width'
    },
    {
      title: '高(in)',
      key: 'height',
      dataIndex: 'height'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (text: string) => statusHandler(text)
    }
  ];

  return (
    <Modal
      closable={false}
      title={<div>头程订单详情</div>}
      cancelText="关闭"
      onCancel={() => dispatch(setShowTouChengDetailModal(false))}
      open={showTouChengDetailModal}
      okButtonProps={{ style: { display: 'none' } }}
      width={1500}
    >
      <Space size="large">
        <div>
          <strong>参考单号:</strong> {hwcTouCheng.waybill}
        </div>
        <div>
          <strong>目的仓库:</strong> {hwcTouCheng.mdck}
        </div>
        <div>
          <strong>寄往国家:</strong> {hwcTouCheng.country}
        </div>
        <div>
          <strong>状态:</strong> {statusHandler(hwcTouCheng.status)}
        </div>
        <div>
          <strong>创建时间:</strong> {dayjs(hwcTouCheng.addtime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </Space>
      <Tabs defaultActiveKey="1">
        <TabPane tab="商品信息" key="1">
          <Table<HwcTouChengOrderProduct>
            rowKey="id"
            dataSource={hwcTouCheng.order_products}
            columns={productColumns}
          />
        </TabPane>
        <TabPane tab="装箱信息" key="2">
          <Table<HwcTouChengOrderProduct>
            rowKey="id"
            dataSource={hwcTouCheng.order_products}
            columns={boxColumns}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default HwcTouchengDetailModal;
